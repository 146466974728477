<template>
    <div id="export-center-form" class="w-50">
        <h1 class="pb-5"> {{ $t('navigation.export_center') }} </h1>
        <FormItem id="vname" :label="$t('navigation.voyages')" type="livesearch" :service="voyageService" placeholder="All" v-model="item.voyage_id" @onchange="getVoyageData" sortField="id" />
        <FormItem type="select" id="bld3" v-model="item.port_of_loading_id" :options="polpodOptions" placeholder="All" :label="$t('sales.loading_ports')" :disabled="loading" @onchange="filterData" />
        <FormItem type="select" id="bld4" v-model="item.port_of_discharge_id" :options="polpodOptions" placeholder="All" :label="$t('sales.discharge_ports')" :disabled="loading" @onchange="filterData" />
        <FormItem type="select" id="bld5" v-if="loading || !isOperator" v-model="item.office_id" :options="offices" :placeholder="defaultOffice" :label="$t('navigation.offices')" :disabled="true" />
        <FormItem type="select" id="bld5" v-else v-model="item.office_id" :options="offices" :selectDefault="false" :label="$t('navigation.offices')" @onchange="setCustomers" />
        <FormItem type="select" id="bld6" v-model="item.customer_id" :options="customers" placeholder="All" :label="$t('navigation.customers')" :disabled="loading" @onchange="filterData" />
        <div class="d-flex">
            <div class="text-danger col-form-label-sm pt-2" style="width: calc(33.3% + .5rem)">
                <span v-show="disableDownloadButton"><i class="fa fa-triangle-exclamation me-2"></i>Select at least 1</span>
            </div>
            <FormItem type="download" id="bld9" :service="download" placeholder="All" :label="$t('operations.download_export')" startTag="fa" :options="{send: true}" :disabled="loading || disableDownloadButton" />
        </div>
    </div>

</template>

<script>
    import VoyageService from '@/services/VoyageService';
    import DataService from '@/services/DataService';
    import store from '@/store/user';

    export default {
        data() {
            return {
                item: {
                    voyage_id: null,
                    port_of_loading_id: null,
                    port_of_discharge_id: null,
                    customer_id: null,
                    office_id: null,
                    show_storage: true,
                    voyage_ids: null
                },
                polpodOptions: [],
                storedCustomers: [],
                customers: [],
                offices: [],
                defaultOffice: null,
                isOperator: false,
                isAdmin: false,
                voyageName: '',
                loading: true,
                saving: false,
                disableDownloadButton: true,
                voyageService: VoyageService
            }
        },
        methods: {
            getVoyageData(){
                if(!this.item.voyage_id){
                    this.getExportCenterData(this.item);
                    return;
                }
                this.loading = true;
                VoyageService.getPortsAndOperators(this.item.voyage_id, true).then(response => {
                    this.voyageName = response.data.number;
                    this.polpodOptions = response.data.ports;
                    this.polpodOptions.sort((a, b) => a.name > b.name ? 1 : -1);
                    this.storedCustomers = response.data.customers;
                    this.loading = false;
                    this.offices = [];
                    response.data.operators.forEach(office => {
                        this.offices.push({
                            id: office.id,
                            name: office.relation.name
                        });
                    });

                    const companyId = store.getters.getCompanyId;
                    let operator = response.data.operators.find(o => o.id == companyId);
                    this.isOperator = companyId == 2 || operator !== undefined;
                    this.offices = this.offices.concat(response.data.offices);
                    if(this.isOperator){
                        this.offices.unshift({
                            id: null,
                            name: this.$t('form.all')
                        });
                    }
                    else if(this.offices.find(o => o.id == 8) && this.offices.find(o => o.id == 30)){
                        this.offices.unshift({
                            id: 'UK',
                            name: 'NMTUK + Sutcliffes'
                        });
                    }
                    this.offices.sort((a, b) => a.name > b.name ? 1 : -1);
                    let operatorOrOffice = this.offices.find(o => o.id == companyId);
                    this.item.office_id = operatorOrOffice ? operatorOrOffice.id : (this.offices[0] ? this.offices[0].id : null);
                    this.item.voyage_ids = null;
                    this.setCustomers();
                    this.disableDownloadButton = false;
                }).catch(e => console.log(e));
            },
            setCustomers(){
                this.customers = this.storedCustomers[this.item.office_id] || [];
                this.customers.sort((a, b) => a.name > b.name ? 1 : -1);
                if(this.customers.length == 0 || !this.customers.find(c => c.id == this.item.customer_id)){
                    this.item.customer_id = null;
                }
            },
            filterData(){
                if(this.item.voyage_id) return;
                this.loading = true;
                this.getExportCenterData(this.item);
            },
            getExportCenterData(itemData){
                return DataService.getExportCenterData(itemData).then(response => {
                    this.loading = false;
                    this.polpodOptions = response.data.ports;
                    this.customers = response.data.customers;
                    this.offices = response.data.offices;
                    this.offices.unshift({
                        id: null,
                        name: this.$t('form.all')
                    });
                    if(!itemData.port_of_loading_id && !itemData.port_of_discharge_id && !itemData.customer_id){
                        this.disableDownloadButton = true;
                    }
                    else{
                        this.disableDownloadButton = false;
                    }
                    this.item.voyage_ids = response.data.voyage_ids;
                }).catch(error => {
                    this.toastError(error)
                })
            },
            download(){
                return VoyageService.downloadExcelExport(this.item).then(response => {
                    this.triggerDownload(response.data, 'Export_' + this.voyageName + '_' + (new Date().getTime()) + '.xlsx');
                }).catch(error => {
                    this.toastError(error)
                })
            }
        },
        mounted() {
            this.loading = true;
            this.defaultOffice = store.getters.getCompanyName;
            this.getExportCenterData(this.item);
            this.isAdmin = store.getters.isAdmin;
        }
    }
</script>